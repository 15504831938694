import api from '@/utils/api'

export default {
    getArenas(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`arenas` + (page ? range : ''))
    },

    getFilteredArenas(search, page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`) + '&filter=' + encodeURIComponent(`{"name":"%${search}%"}`)
        return api.get(`arenas` + (page ? range : ''))
    },

    getArena(id) {
        return api.get(`arenas/${id}`)
    },

    createArena(data) {
        return api.post('arenas', data)
    },

    updateArena(id, data) {
        return api.put(`arenas/${id}`, data)
    },

    uploadFile(formData) {
        return api.uploadFile('uploads/arena', formData)
    },

    deleteArena(id) {
        return api.delete(`arenas/${id}`)
    }
}
