import api from '@/utils/api'

export default {
    getSeasons(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`seasons` + (page ? range : ''))
    },

    getSeason(id) {
        return api.get(`seasons/${id}`)
    },

    updateSeason(id, data) {
        return api.put(`seasons/${id}`, data)
    },

    createSeason(data) {
        return api.post('seasons', data)
    },

    deleteSeason(id) {
        return api.delete(`seasons/${id}`)
    }
}
