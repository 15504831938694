import api from '@/utils/api'

export default {
    getLeagues(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`leagues` + (page ? range : ''))
    },

    getLeague(id) {
        return api.get(`leagues/${id}`)
    },

    getAllActiveLeagues() {
        let query = '?filter=' + encodeURIComponent('{"active": true }')
        return api.get(`leagues` + query)
    },

    getAllLeagues() {
        return api.get(`leagues/all`)
    },

    updateLeague(id, data) {
        return api.put(`leagues/${id}`, data)
    },

    createLeague(data) {
        return api.post('leagues', data)
    },

    deleteLeague(id) {
        return api.delete(`leagues/${id}`)
    }
}
