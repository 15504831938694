<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Match Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6" lg="4">
                    <v-autocomplete
                        v-model="match.arena"
                        :items="arenas"
                        item-text="name"
                        menu-props="auto"
                        label="Arena"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-switch
                        v-model="match.cup"
                        hide-details
                        inset
                        :label="match.cup ? 'Cup' : 'Not Cup'"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-switch
                        v-model="match.postponed"
                        hide-details
                        inset
                        :label="match.postponed ? 'Postponed' : 'Not Postponed'"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-file-input
                        v-model="pdfFile"
                        class="mt-3"
                        accept=".pdf"
                        label="Pdf file"
                        :clearable="clearable"
                        @change="changePdfFile"
                        @click:clear="clearPdfFile()"
                    />
                </v-col>
                <v-col cols="12" lg="4">
                    <date-time-picker
                        :date-label="'Match Date'"
                        :date="matchDate"
                        @setDate="setMatchDate"
                        :time-label="'Match Time'"
                        :time="matchTime"
                        :displayTime="true"
                        @setTime="setMatchTime"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-select
                        v-model="match.phase"
                        :items="['placements', 'regular_season', 'playoffs']"
                        menu-props="auto"
                        label="Phase"
                        hide-details
                        :clearable="!readOnly"
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-text-field
                        v-model="match.round"
                        label="Round"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <!-- <v-col cols="6" lg="4">
                    <v-select
                        v-model="match.type"
                        :items="['winter', 'summer']"
                        menu-props="auto"
                        label="Season Type"
                        hide-details
                        :clearable="!readOnly"
                    />
                </v-col> -->
                <v-col cols="6" lg="4">
                    <v-autocomplete
                        v-model="match.season"
                        :items="seasons"
                        :item-text="(item) => `${item.year} - ${item.type}`"
                        menu-props="auto"
                        label="Season"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="6" lg="4">
                    <v-autocomplete
                        v-model="match.league"
                        :items="leagues"
                        item-text="title"
                        menu-props="auto"
                        label="League"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="match.videoUrl"
                        label="video url"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row class="my-15 custom-border mx-1" align="center">
                <v-col cols="6" lg="3">
                    <v-autocomplete
                        v-model="match.team1"
                        :items="teams.filter((team) => team.active)"
                        item-text="name"
                        menu-props="auto"
                        label="Team1"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
                <v-col cols="6" lg="2">
                    <v-text-field
                        v-model="match.scoreTeam1"
                        type="number"
                        label="team1 score"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="12" lg="2" align="center">
                    vs
                </v-col>
                <v-col cols="6" lg="2">
                    <v-text-field
                        v-model="match.scoreTeam2"
                        type="number"
                        label="team2 score"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6" lg="3">
                    <v-autocomplete
                        v-model="match.team2"
                        :items="teams.filter((team) => team.active)"
                        item-text="name"
                        menu-props="auto"
                        label="Team2"
                        hide-details
                        :clearable="!readOnly"
                        return-object
                    />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="6">
                    <v-file-input
                        v-model="csvFileTeam1"
                        class="mt-3"
                        accept=".csv,.txt"
                        show-size
                        label="Team1 stats csv"
                        :clearable="clearable"
                        @change="changeCsvFileTeam1"
                        @click:clear="clearCsvFileTeam1()"
                    />
                </v-col>
                <v-col cols="6">
                    <v-file-input
                        v-model="csvFileTeam2"
                        class="mt-3"
                        accept=".csv,.txt"
                        show-size
                        label="Team2 stats csv"
                        :clearable="clearable"
                        @change="changeCsvFileTeam2"
                        @click:clear="clearCsvFileTeam2()"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" :loading="loading" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import matchService from '@/services/match'
    import Alert from '@/components/Alert'
    import DateTimePicker from '@/components/DateTimePicker'
    import momentTimezone from 'moment-timezone'
    import arenaService from '@/services/arena'
    import teamService from '@/services/team'
    import seasonService from '@/services/season'
    import leagueService from '@/services/league'

    export default {
        name: 'MatchForm',
        components: { Alert, DateTimePicker },
        data: () => {
            return {
                showAlert: false,
                matchDate: null,
                matchTime: null,
                message: null,
                type: null,
                loading: false,
                test: {},
                mode: null,
                readOnly: false,
                clearable: true,
                match: {
                    arena: {
                        name: ''
                    },
                    season: {
                        name: ''
                    },
                    team1: {
                        name: ''
                    },
                    team2: {
                        name: ''
                    }
                },
                durationTypes: ['DAILY', 'WEEKLY'],
                promoTypes: ['SLOTS', 'PS_BATTLES', 'LIVE_CASINO', 'PS_CHALLENGE', 'ROULETTE'],
                matchId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                pdfFile: null,
                csvFileTeam1: null,
                csvFileTeam2: null,
                pdfFileChanged: false,
                csvFileTeam1Changed: false,
                csvFileTeam2Changed: false,
                arenas: [],
                teams: [],
                seasons: [],
                leagues: []
            }
        },
        watch: {
            'match.team1': {
                handler() {
                    this.setLeague()
                }
            },
            'match.team2': {
                handler() {
                    this.setLeague()
                }
            },
            'match.cup': {
                handler() {
                    this.setLeague()
                }
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllArenas()
            this.fetchAllTeams()
            this.fetchAllSeasons()
            this.fetchAllLeagues()
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.matchId = this.$route.params.matchId
            if (this.matchId) {
                matchService.getMatch(this.matchId).then(resp => {
                    this.match = resp.data
                    this.pdfFile = this.match.file
                    if (this.match.matchDatetime === null) {
                        this.matchDate = null
                        this.matchTime = null
                    } else {
                        let matchDate = momentTimezone(this.match.matchDatetime).tz('Europe/Athens').format('YYYY-MM-DD[T]HH:mm:ss').toString()
                        matchDate = matchDate.split('T')
                        this.matchDate = matchDate[0]
                        this.matchTime = matchDate[1]
                    }
                })
            }
        },
        methods: {
            setLeague() {
                if (this.match.cup) {
                    this.match.league = null
                } else {
                    if (this.match.team1.league.id === this.match.team2.league.id) {
                        this.match.league = this.match.team1.league
                    }
                }
            },
            changeCsvFileTeam1() {
                this.csvFileTeam1Changed = true
                // if (this.csvFileTeam1) {
                //     this.csvFileTeam1 = URL.createObjectURL(this.csvFileTeam1)
                // }
            },
            clearCsvFileTeam1() {
                this.csvFileTeam1Path = ''
            },
            changeCsvFileTeam2() {
                this.csvFileTeam2Changed = true
                // if (this.csvFileTeam2) {
                //     this.csvFileTeam2 = URL.createObjectURL(this.csvFileTeam2)
                // }
            },
            clearCsvFileTeam2() {
                this.csvFileTeam2Path = ''
            },
            changePdfFile() {
                this.pdfFileChanged = true
                if (this.pdfFile) {
                    this.pdfFilePath = URL.createObjectURL(this.pdfFile)
                }
            },
            clearPdfFile() {
                this.pdfFilePath = ''
                this.match.file = null
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            changeImage() {
                this.imageChanged = true
                this.imagePath = URL.createObjectURL(this.image)
            },
            changeIcon() {
                this.iconChanged = true
                this.iconPath = URL.createObjectURL(this.icon)
            },
            setMultilingualConfig(data) {
                this.match.multilingualConfigs = data
            },
            shouldDisableSubmit() {
                return false
            },
            setMatchDate(date) {
                this.matchDate = date
            },
            setMatchTime(time) {
                this.matchTime = time
            },
            cleaMatchDateTime() {
                this.matchDate = null
                this.matchTime = null
            },
            createMatch() {
                return matchService.createMatch(this.match).then((resp) => {
                    this.matchId = resp.data.id
                    this.loading = false
                })
            },
            editMatch() {
                return matchService.updateMatch(this.matchId, this.match).then(() => {
                    this.loading = false
                })
            },
            onSubmit() {
                this.loading = true
                if (this.matchDate && this.matchTime) {
                    this.match.matchDatetime = momentTimezone(`${this.matchDate} ${this.matchTime}`).tz('Europe/Athens').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()
                } else {
                    delete this.match.matchDatetime
                }
                const myPromise = new Promise((resolve) => {
                    resolve(this.uploadPdf())
                })
                myPromise.then(() => this.mode === 'edit' ? this.editMatch() : this.createMatch())
                    .then(() => this.uploadCsvs(this.matchId, this.csvFileTeam1, this.match.team1?.id, this.csvFileTeam1Changed))
                    .then(() => this.uploadCsvs(this.matchId, this.csvFileTeam2, this.match.team2?.id, this.csvFileTeam2Changed))
                    .then(() => this.$router.push(`/matches`))
                    .catch((error) => {
                        this.setAlert(error.response.data.message, 'error')
                        this.loading = false
                    })
            },
            uploadCsvs(matchId, csvFileTeam, teamId, csvFileChanged) {
                if (csvFileChanged && csvFileTeam) {
                    let formData = new FormData()
                    formData.append('file', csvFileTeam)
                    return matchService.importCsvStats(matchId, teamId, formData).then(() => {
                        this.loading = false
                    })
                }
            },
            uploadPdf() {
                if (!this.pdfFileChanged || this.pdfFile == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.pdfFile)
                return matchService.uploadFile(formData, 'pdf').then((response) => {
                    this.match.file = response.data.content
                })
            },
            fetchAllArenas() {
                arenaService.getArenas().then(resp => {
                    this.arenas = resp.data.content
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            fetchAllTeams() {
                teamService.getTeams().then(resp => {
                    this.teams = resp.data.content
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            fetchAllSeasons() {
                seasonService.getSeasons().then(resp => {
                    this.seasons = resp.data.content
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            fetchAllLeagues() {
                leagueService.getAllLeagues().then(resp => {
                    this.leagues = resp.data
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                })
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.custom-border {
    border: 2px solid black;
}
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
